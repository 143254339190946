import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const WorkingHours = ({ activeProjects, signed, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <Card {...rest} sx={{ height: '100%' }}>
      <CardHeader title={t('dashboard.workingHours')} />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('jobsAndProjects.projectNumber')}
                </TableCell>
                <TableCell>
                  <TableSortLabel>
                    {t('dashboard.travelHours')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>
                    {t('dashboard.workHours')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeProjects.map((project) => (
                <TableRow
                  hover
                  key={project.project}
                  onClick={() => window.location.assign(`/app/project/${project.project}`)}
                >
                  <TableCell>
                    {project.project}
                  </TableCell>
                  <TableCell>
                    {project.travelHours}
                  </TableCell>
                  <TableCell>
                    {project.workingHours}
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

WorkingHours.propTypes = {
  activeProjects: PropTypes.array.isRequired,
};

export default WorkingHours;
