import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';

let states = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'app_user',
    label: 'App User',
  }
];

const AccountProfileDetails = ({
  userData, edit, cancelButton, currentUser, ...rest
}) => {
  const [values, setValues] = useState({
    name: userData.name || '',
    email: userData.email || '',
    mobile: userData.mobile || '',
    role: userData.role || 'app_user',
    id: userData.id || '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      noValidate
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First and last name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="mobile"
                onChange={handleChange}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select Role"
                name="role"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.role}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {cancelButton && (
          <Button
            style={{ backgroundColor: 'lightgrey', color: 'black' }}
            sx={{ marginRight: 2 }}
            variant="contained"
            onClick={() => rest.oncancel()}
          >
            Cancel
          </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => (edit ? rest.changeuser(values) : rest.handler(values))}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

AccountProfileDetails.propTypes = {
  userData: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  cancelButton: PropTypes.bool.isRequired,
};

export default AccountProfileDetails;
