import { Helmet } from 'react-helmet';
import React from 'react';
import { Box, colors } from '@material-ui/core';
import ProjectListResults from 'src/components/projects/ProjectListResults';
import auth from '../firebase';

class ArchiveList extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    // COMPANIES FOR SELECTION DEPENDING ON USER ROLE
    this.state = {
      projects: [], userData: {}, mounted: false
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getProjectData();
      }
    });
  }

  getProjectData() {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    this.setState({ userData });
    return auth.app.firestore()
      .collection('projects')
      .where('disabled', '==', true)
      .get()
      .then((snap) => {
        const projects = [];
        const promises = [];

        snap.docs.forEach((doc) => {
          projects.push({ ...doc.data(), ...{ id: doc.id, createdAt: doc.data().createdAt.seconds ? doc.data().createdAt.toDate() : new Date() } });
        });

        return Promise.all(promises).then(() => {
          this.setState({ projects, mounted: true });
          return null;
        });
      });
  }

  setProjects(projects) {
    this.setState({
      projects
    });
  }

  render() {
    const {
      projects, userData, mounted
    } = this.state;

    return (
      <>
        <Helmet>
          <title>Projects | Elan daily reporting</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          {mounted
          && <ProjectListResults refresh={() => this.getProjectData()} handler={(prop) => this.setProjects(prop)} projects={projects} userData={userData} archived={true} />}
        </Box>
      </>
    );
  }
}

export default ArchiveList;
