import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBsx9J0yqMBnO-gInOcRRDl7Iro9-jrW9w",
  authDomain: "elan-daily-reporting.firebaseapp.com",
  projectId: "elan-daily-reporting",
  storageBucket: "elan-daily-reporting.appspot.com",
  messagingSenderId: "34046605385",
  appId: "1:34046605385:web:6958510ecade7d8fe82798"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
export default auth;
