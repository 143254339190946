import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestUsers from 'src/components/dashboard/LatestUsers';
import auth from '../firebase';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      userData: {}, reports: [], users: [], lastSigned: [], lastMissed: []
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
      }
    });
  }

  async getUserData() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const userData = userSnap.data();
        this.setState({ userData });
        this.setUsers();
        this.setReports();
        sessionStorage.setItem('user', JSON.stringify({ ...userData, ...{ id: auth.currentUser.uid } }));
        const event = document.createEvent('Event');
        event.initEvent('user-data-loaded', true, true);
        window.dispatchEvent(event);
      });
  }

  async setReports() {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
    auth.app.firestore().collection('reports')
      .where('date', '>=', oneWeekAgo)
      .get()
      .then((response) => {
        const reports = [];
        response.docs.forEach((doc) => {
          reports.push({ ...doc.data(), ...{ id: doc.id } });
        });
        reports.sort((a, b) => b.date.toDate() - a.date.toDate());

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        this.setState({ reports, lastSigned: reports.filter((r) => r.signed)?.slice(0, 10), lastMissed: reports.filter((r) => !r.signed && r.date.toDate() <= yesterday)?.slice(0, 10) });
        this.getMissedReports(reports);
      });
  }

  getMissedReports(reports) {
    if (reports?.length) {

    }
  }

  setUsers() {
      return auth.app.firestore().collection('users').get().then((response) => {
        const users = [];
        const promises = [];
        response.docs.forEach((doc) => {
          if (doc.data().avatar) {
            promises.push(auth.app.storage().ref(doc.data().avatar).getDownloadURL().then((url) => {
              users.push({ ...doc.data(), ...{ id: doc.id, avatarUrl: url } });
            })
              .catch(() => users.push({ ...doc.data(), ...{ id: doc.id } })));
          } else {
            users.push({ ...doc.data(), ...{ id: doc.id } });
          }
        });
        return Promise.all(promises).then(() => {
          this.setState({ users });
        });
      });
  }

  render() {
    const {
      userData, reports,lastSigned,users, lastMissed
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Dashboard | Elan Daily Reporting
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={6}
                md={12}
                xl={6}
                xs={12}
              >
                {<LatestOrders currentReports={lastSigned} signed={true} />}
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xl={6}
                xs={12}
              >
                {<LatestOrders currentReports={lastMissed} signed={false} />}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default Dashboard;
