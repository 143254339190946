import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  Container,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  Grid,
  TableSortLabel,
} from '@material-ui/core';
import { IconButton } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Search as SearchIcon, Zap as EnableIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import auth from '../../firebase';

const OperativeListResults = ({
  customers, currentUser, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [limit, setLimit] = useState(8);
  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const search = (event) => {
    const txt = event.target.value.toLowerCase();
    if (txt && txt.length > 0) {
      setFilteredCustomers(customers.filter((c) => c.id.toLowerCase().includes(txt)));
    } else {
      setFilteredCustomers([]);
    }
  };

  function compare(a, b, type, ord) {
    const str = (typeof a[type] === 'string' || a[type] instanceof String);
    let aVar = str ? a[type].toLowerCase() : a[type];
    let bVar = str ? b[type].toLowerCase() : b[type];
    if (type === 'createdAt') {
      aVar = a.createdAt ? new Date(a.createdAt) : new Date();
      bVar = b.createdAt ? new Date(b.createdAt) : new Date();
    }
    if (aVar < bVar) {
      return ord ? -1 : 1;
    }
    if (aVar > bVar) {
      return ord ? 1 : -1;
    }
    return 0;
  }

  function changeSort(type) {
    if (type) {
      if (orderBy === type) {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(type);
        const newUsers = customers;
        newUsers.sort((a, b) => compare(a, b, type, newOrder === 'asc'));
        setFilteredCustomers(newUsers);
      } else {
        setOrderBy(type);
        const newUsers = customers;
        newUsers.sort((a, b) => compare(a, b, type, order === 'asc'));
        setFilteredCustomers(newUsers);
      }
    }
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function deleteUsers() {
    setLoading(true);
    const promises = [];
    selectedCustomerIds.forEach((uid) => {
      promises.push(auth.app.firestore().collection('operatives').doc(uid).delete());
    });
      Promise.all(promises).finally(() => {
        rest.handler(customers.filter((c) => !selectedCustomerIds.includes(c.id)));
        setLoading(false);
        setSelectedCustomerIds([]);
      });
  }

  function addUser() {
    setEdit(false);
    setModalOpened(true);
  }

  async function makeUser() {
    const newUser = {
      activeProjects: [],
      createdAt: new Date(),
      id: name.trim(),
    };
    await auth.app.firestore().collection('operatives').doc(name).set(newUser);
    await auth.app.firestore()
      .collection('operatives')
      .get()
      .then((snap) => {
        const users = [];
        snap.docs.forEach((doc) => {
            users.push({ ...doc.data(), ...{ id: doc.id } });
        });
        rest.handler(users);
      });
    setModalOpened(false);
    setName('');
  }

  return (
    <Container maxWidth={false}>
      <Box>
        <Box>
          <Card>
            <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{ maxWidth: 500, width: '60%' }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={search}
                  placeholder={t('users.search')}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {selectedCustomerIds && selectedCustomerIds.length > 0
                  ? (
                    <>
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => deleteUsers()}
                      >
                        <div style={{paddingRight: 8, paddingLeft: 8}}>{t('users.delete')}</div>
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginLeft: 12 }}
                      onClick={() => addUser()}
                    >
                      <div style={{paddingRight: 8, paddingLeft: 8}}>{t('users.addOperative')}</div>
                    </Button>
                  )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Card>
          <PerfectScrollbar>
            <Box sx={{ minWidth: isMobile ? 200 : 500 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.length === customers.length}
                        color="primary"
                        indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'id'}
                        direction={orderBy === 'id' ? order : 'asc'}
                        onClick={() => changeSort('id')}
                      >
                        {t('common.name')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <TableSortLabel
                        active={orderBy === 'createdAt'}
                        direction={orderBy === 'createdAt' ? order : 'asc'}
                        onClick={() => changeSort('createdAt')}
                      >
                        {t('users.registrationDate')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ opacity: loading ? 0.3 : 1 }}>
                  {(filteredCustomers.length > 0 ? filteredCustomers : customers).slice(0 + page * limit, limit * (page + 1)).map((customer) => (
                    <TableRow
                      sx={{ cursor: customer.disabled ? 'auto' : 'pointer', backgroundColor: customer.disabled ? 'rgba(245,245,245 , 0.5 )' : null }}
                      hover
                      key={customer.id}
                      selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        {customer.disabled ? (
                          <IconButton onClick={() => enable(customer.id)} aria-label="settings" color="#43a047">
                            <EnableIcon
                              size={20}
                              style={{ color: "#1b5e20", opacity: 1 }}
                            />
                          </IconButton>
                        )
                          : (
                            <Checkbox
                              color="primary"
                              checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                              onChange={(event) => handleSelectOne(event, customer.id)}
                              value="true"
                            />
                          )}
                      </TableCell>
                      <TableCell onClick={() => window.location.assign(`operative/${customer.id}`)}>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            maxWidth: isMobile ? 40 : 200,
                            opacity: customer.disabled ? 0.5 : 1
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            variant={isMobile ? 'body2' : 'body1'}
                          >
                            {customer.id}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ maxWidth: isMobile ? 40 : 200, textAlign: 'right', opacity: customer.disabled ? 0.5 : 1 }}>
                        {customer.createdAt ? moment(customer.createdAt.toDate()).format('DD/MM/YYYY') : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={customers.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[8, 16, 24]}
          />
        </Card>
      </Box>
      <Dialog
        onBackdropClick={() => setModalOpened(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 20,
          minWidth: '100vw',
          justifyContent: 'center'
        }}
        open={modalOpened}
      >
        <Grid
          container
          width={isMobile ? '90vw' : null}
          spacing={0}
          sx={{ backgroundColor: 'white' }}
        >
                      <Card>
              <CardHeader
                title={t('users.addOperative')}
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    width={300}
                    md={12}
                    xs={12}
                  >          <TextField
                  fullWidth
                  label=""
                  name="operativename"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  variant="outlined"
                /></Grid>
                                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={{ backgroundColor: 'lightgrey', color: 'black' }}
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  onClick={() => setModalOpened(false)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => makeUser()}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Card>
        </Grid>
      </Dialog>
    </Container>
  );
};

OperativeListResults.propTypes = {
  customers: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default OperativeListResults;
