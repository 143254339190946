import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  Container,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  Grid,
  TableSortLabel,
} from '@material-ui/core';
import { IconButton } from '@mui/material';
import { isMobile } from 'react-device-detect';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
import { Search as SearchIcon, Zap as EnableIcon } from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { green } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import auth from '../../firebase';

const CustomerListResults = ({
  customers, currentUser, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [limit, setLimit] = useState(6);
  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const search = (event) => {
    const txt = event.target.value.toLowerCase();
    if (txt && txt.length > 0) {
      setFilteredCustomers(customers.filter((c) => c.name.toLowerCase().includes(txt) || c.email.toLowerCase().includes(txt)));
    } else {
      setFilteredCustomers([]);
    }
  };

  function compare(a, b, type, ord) {
    const str = (typeof a[type] === 'string' || a[type] instanceof String);
    let aVar = str ? a[type].toLowerCase() : a[type];
    let bVar = str ? b[type].toLowerCase() : b[type];
    if (type === 'metadata') {
      aVar = a.metadata ? new Date(a.metadata.creationTime) : new Date();
      bVar = b.metadata ? new Date(b.metadata.creationTime) : new Date();
    }
    if (aVar < bVar) {
      return ord ? -1 : 1;
    }
    if (aVar > bVar) {
      return ord ? 1 : -1;
    }
    return 0;
  }

  function changeSort(type) {
    if (type) {
      if (orderBy === type) {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(type);
        const newUsers = customers;
        newUsers.sort((a, b) => compare(a, b, type, newOrder === 'asc'));
        setFilteredCustomers(newUsers);
      } else {
        setOrderBy(type);
        const newUsers = customers;
        newUsers.sort((a, b) => compare(a, b, type, order === 'asc'));
        setFilteredCustomers(newUsers);
      }
    }
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function deleteUsers() {
    setLoading(true);
    const deleteCall = auth.app.functions('europe-west1').httpsCallable('deleteusers');
    const promises = [];
    selectedCustomerIds.forEach((uid) => {
      promises.push(auth.app.firestore().collection('users').doc(uid).delete());
    });
    deleteCall({ uids: selectedCustomerIds }).then(() => {
      Promise.all(promises).finally(() => {
        rest.handler(customers.filter((c) => !selectedCustomerIds.includes(c.id)));
        setLoading(false);
        setSelectedCustomerIds([]);
      });
    });
  }

  function disableUsers() {
    setLoading(true);
    const promises = [];
    selectedCustomerIds.forEach((uid) => {
      const disableCall = auth.app.functions('europe-west1').httpsCallable('disableuser');
      promises.push(auth.app.firestore().collection('users').doc(uid).update({ disabled: true }));
      promises.push(disableCall({ uid }));
    });
    Promise.all(promises).then(() => {
      rest.refresh();
      setLoading(false);
      setSelectedCustomerIds([]);
    });
  }

  function addUser() {
    setEdit(false);
    setModalOpened(true);
  }

  function enable(uid) {
    setLoading(true);
    const promises = [];
    const enableCall = auth.app.functions('europe-west1').httpsCallable('enableuser');
    promises.push(auth.app.firestore().collection('users').doc(uid).update({ disabled: false }));
    promises.push(enableCall({ uid }));
    Promise.all(promises).then(() => {
      setLoading(false);
      rest.refresh();
    });
  }

  function editUser(usr) {
    setEdit(true);
    setUserData(usr);
    setModalOpened(true);
  }

  function setUserDetails(details) {
    if (details) {
      setLoading(true);
      setModalOpened(false);
      const userAdd = auth.app.functions('europe-west1').httpsCallable('adduser');
      userAdd(details).then(() => { rest.refresh(); setLoading(false); });
    }
  }

  function changeUserDetails(details) {
    if (details) {
      setLoading(true);
      setModalOpened(false);
      const userAdd = auth.app.functions('europe-west1').httpsCallable('edituser');
      userAdd(details).then(() => { rest.refresh(); setLoading(false); });
    }
  }

  return (
    <Container maxWidth={false}>
      <Box>
        <Box>
          <Card>
            <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{ maxWidth: 500, width: '60%' }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={search}
                  placeholder={t('users.search')}
                  variant="outlined"
                />
              </Box>
              <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  {selectedCustomerIds && selectedCustomerIds.length > 0
                    ? (
                      <>
                        <Button
                          style={{ marginLeft: 12 }}
                          color="error"
                          variant="contained"
                          onClick={() => deleteUsers()}
                        >
                          <div style={{paddingRight: 8, paddingLeft: 8}}>{t('users.delete')}</div>
                        </Button>
                        <Button
                          style={{ marginLeft: 12, backgroundColor: 'grey' }}
                          variant="contained"
                          onClick={() => disableUsers()}
                        >
                          <div style={{paddingRight: 8, paddingLeft: 8}}>{t('users.disable')}</div>
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 12 }}
                        onClick={() => addUser()}
                      >
                        <div style={{paddingRight: 8, paddingLeft: 8}}>{t('users.add')}</div>
                      </Button>
                    )}
                </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Card>
          <PerfectScrollbar>
            <Box sx={{ minWidth: isMobile ? 200 : 500 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.length === customers.length}
                        color="primary"
                        indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => changeSort('name')}
                      >
                        {t('common.name')}
                      </TableSortLabel>
                    </TableCell>
                    {!isMobile && (
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'email'}
                        direction={orderBy === 'email' ? order : 'asc'}
                        onClick={() => changeSort('email')}
                      >
                        {t('common.email')}
                      </TableSortLabel>
                    </TableCell>
                    )}
                    <TableCell>
                      {t('common.role')}
                    </TableCell>
                    {!isMobile && (
                    <TableCell>
                      {t('common.phone')}
                    </TableCell>
                    )}
                    <TableCell sx={{ textAlign: 'right' }}>
                      <TableSortLabel
                        active={orderBy === 'metadata'}
                        direction={orderBy === 'metadata' ? order : 'asc'}
                        onClick={() => changeSort('metadata')}
                      >
                        {t('users.registrationDate')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ opacity: loading ? 0.3 : 1 }}>
                  {(filteredCustomers.length > 0 ? filteredCustomers : customers).slice(0 + page * limit, limit * (page + 1)).map((customer) => (
                    <TableRow
                      sx={{ cursor: customer.disabled ? 'auto' : 'pointer', backgroundColor: customer.disabled ? 'rgba(245,245,245 , 0.5 )' : null }}
                      hover
                      key={customer.id}
                      selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        {customer.disabled ? (
                          <IconButton onClick={() => enable(customer.id)} aria-label="settings" color="#43a047">
                            <EnableIcon
                              size={20}
                              style={{ color: "#1b5e20", opacity: 1 }}
                            />
                          </IconButton>
                        )
                          : (
                            <Checkbox
                              color="primary"
                              checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                              onChange={(event) => handleSelectOne(event, customer.id)}
                              value="true"
                            />
                          )}
                      </TableCell>
                      <TableCell onClick={() => (customer.disabled ? null : editUser(customer))}>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            maxWidth: isMobile ? 40 : 200,
                            opacity: customer.disabled ? 0.5 : 1
                          }}
                        >
                          {!isMobile && (
                          <Avatar
                            src={customer.avatarUrl}
                            sx={{ mr: 2 }}
                          >
                            {getInitials(customer.name)}
                          </Avatar>
                          )}
                          <Typography
                            color="textPrimary"
                            variant={isMobile ? 'body2' : 'body1'}
                          >
                            {customer.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      {!isMobile && (
                      <TableCell sx={{ opacity: customer.disabled ? 0.5 : 1 }} onClick={() => (customer.disabled ? null : editUser(customer))}>
                        {customer.email}
                      </TableCell>
                      )}
                      <TableCell sx={{ maxWidth: isMobile ? 40 : 200, opacity: customer.disabled ? 0.5 : 1 }}>
                        {customer.role ? customer.role.replace('_', ' ') : ''}
                      </TableCell>
                      {!isMobile && (
                      <TableCell sx={{ opacity: customer.disabled ? 0.5 : 1 }}>
                        {customer.mobile}
                      </TableCell>
                      )}
                      <TableCell sx={{ maxWidth: isMobile ? 40 : 200, textAlign: 'right', opacity: customer.disabled ? 0.5 : 1 }}>
                        {customer.metadata ? moment(customer.metadata.creationTime).format('DD/MM/YYYY') : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={customers.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[6, 12, 18]}
          />
        </Card>
      </Box>
      <Dialog
        onBackdropClick={() => setModalOpened(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 20,
          minWidth: '100vw',
          justifyContent: 'center'
        }}
        open={modalOpened}
      >
        <Grid
          container
          width={isMobile ? '90vw' : null}
          spacing={0}
          sx={{ backgroundColor: 'white' }}
        >
          <AccountProfileDetails cancelButton oncancel={() => setModalOpened(false)} handler={(prop) => setUserDetails(prop)} changeuser={(prop) => changeUserDetails(prop)} edit={edit} currentUser={currentUser} userData={edit ? userData : {}} />
        </Grid>
      </Dialog>
    </Container>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default CustomerListResults;
