import { Helmet } from 'react-helmet';
import React from 'react';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import OperativeListResults from 'src/components/operative/OperativeListResults';
import auth from '../firebase';

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { customers: [], currentUser: {} };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getOperatives();
        this.getCurrentUser();
      }
    });
  }

  getCurrentUser() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const currentUser = userSnap.data();
        this.setState({ currentUser });
      });
  }

  setCustomers(customers) {
    this.setState({
      customers
    });
  }

  getOperatives() {
    return auth.app.firestore()
      .collection('operatives')
      .get()
      .then((snap) => {
        const users = [];
        snap.docs.forEach((doc) => {
            users.push({ ...doc.data(), ...{ id: doc.id } });
        });
          this.setState({ customers: users });
      });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const { customers, currentUser } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {t('common.users')}
            {' '}
            | Elan daily reporting
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            py: 3,
          }}
        >
          <OperativeListResults refresh={() => this.getUserData()} handler={(prop) => this.setCustomers(prop)} customers={customers} currentUser={currentUser} />
        </Box>
      </>
    );
  }
}

export default withTranslation()(CustomerList);
