import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const LatestOrders = ({ currentReports, signed, noStatus, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <Card {...rest} sx={{ height: '100%' }}>
      <CardHeader title={signed ? t('dashboard.latestOrders') : t('dashboard.missedReports')} />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                {t('jobsAndProjects.projectNumber')}
                </TableCell>
                <TableCell>
                  <TableSortLabel>
                    {t('jobsAndProjects.date')}
                  </TableSortLabel>
                </TableCell>
                {!noStatus && <TableCell>
                  <TableSortLabel>
                    {t('jobsAndProjects.status')}
                  </TableSortLabel>
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentReports.map((report) => (
                <TableRow
                  hover
                  key={report.id}
                  onClick={() => window.location.assign(`/app/project/${report.project}`)}
                >
                  <TableCell>
                  {report.project}
                  </TableCell>
                  <TableCell>
                    {moment(report.date.toDate()).format('DD/MM/YYYY')}
                  </TableCell>
                  {!noStatus && <TableCell>
                    <Chip
                      style={{
                        color: 'white',
                        backgroundColor: signed
                        ? '#bfc839'
                        : '#ffc211',
                      }}
                      label={signed ? 'Signed' : 'Missed'}
                      size="small"
                    />
                </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          {t('dashboard.viewAll')}
        </Button>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  currentReports: PropTypes.array.isRequired,
  signed: PropTypes.bool.isRequired,
  noStatus: PropTypes.bool,
};

export default LatestOrders;
