import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import {makeStyles} from '@material-ui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    maxWidth: 800,
  },
  highlightedDate: {
    backgroundColor: '#ffeb3b',
    borderRadius: '50%',
    height: 36,
    width: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

const StaticCalendar = ({
    highlightedDates, selectedDate, handler, ...rest
  }) => {

    const renderWeekPickerDay = (props) => {
    const { highlightedDates = [], day, ...other } = props;
    const isSelected = highlightedDates?.length > 0 && highlightedDates.some(
        highlightedDate =>
        highlightedDate.getDate() === day.getDate() &&
        highlightedDate.getMonth() === day.getMonth() &&
        highlightedDate.getFullYear() === day.getFullYear(),
    );

    const isToday = selectedDate.getDate() === day.getDate() &&
    selectedDate.getMonth() === day.getMonth() &&
    selectedDate.getFullYear() === day.getFullYear();

    return (
        <PickersDay {...other} day={day} sx={isSelected && !isToday ? { borderRadius: '50%', border: '1px solid orange' } : {}} />
    );
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            renderInput={props => <TextField label="Select date of report" helperText="Something" /> }
            value={selectedDate}
            slotProps={{
                actionBar: {
                    actions: []
                },
                toolbar: {
                    hidden: true
                },
                day: {
                    highlightedDates,
                }
            }}
            slots={{
                day: renderWeekPickerDay
            }}
            inputFormat="DD-MM-YYYY"
            onChange={handler}            
          />
    </LocalizationProvider>
  );
};

StaticCalendar.propTypes = {
    highlightedDates: PropTypes.array.isRequired,
    selectedDate: PropTypes.any.isRequired,
    handler: PropTypes.func.isRequired,
};

export default StaticCalendar;
