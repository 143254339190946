import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  Select,
  MenuItem
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Trello as ProjectsIcon,
  Briefcase as BriefCaseIcon,
  DollarSign as DollarSignIcon,
  Users as UsersIcon,
  Archive as ArchiveIcon
} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';
import auth from '../firebase';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const { language } = i18n;
  const location = useLocation();
  const [userData, setUserData] = useState(null);

  const changeLanguage = (lng, id) => {
    i18n.changeLanguage(lng);
    const userId = (userData && userData.id) || id;
    if (userId) {
       auth.app.firestore()
        .collection('users')
        .doc(userId)
        .update({ language: lng });
    }

    const userDataW = JSON.parse(window.sessionStorage.user);

    if (userDataW?.language) {
      sessionStorage.setItem('user', JSON.stringify({ ...userDataW, ...{ language: lng } }));
    }
  };

  const initializeLanguage = (usrData) => {
    if (
      usrData.language
      && usrData.language !== language
    ) {
      changeLanguage(usrData.language, usrData.id);
    }
  };

  if (sessionStorage && sessionStorage.user && userData === null) {
    const usrData = JSON.parse(window.sessionStorage.user);
    setUserData(usrData);
    initializeLanguage(usrData);
    if (usrData.avatar) {
      auth.app.storage().ref(usrData.avatar).getDownloadURL().then((url) => setAvatarUrl(url))
        .catch(() => null);
    }
  } else {
    window.addEventListener('user-data-loaded', () => {
      if (window.sessionStorage && window.sessionStorage.user && userData === null) {
        const usrData = JSON.parse(window.sessionStorage.user);
        setUserData(usrData);
        initializeLanguage(usrData);
        if (usrData.avatar) {
          auth.app.storage().ref(usrData.avatar)?.getDownloadURL().then((url) => setAvatarUrl(url))
            .catch(() => null);
        }
      }
    }, { once: true });
  }
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const items = [
    {
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: t('dashboard.dashboard'),
    },
    {
      href: '/app/customers',
      icon: UsersIcon,
      title: t('sideBar.manageUsers'),
    },
    {
      href: '/app/operatives',
      icon: UsersIcon,
      title: t('sideBar.manageOperatives'),
    },
    {
      href: '/app/projects',
      icon: ProjectsIcon,
      title: t('sideBar.manageProjects'),
    },
    {
      href: '/app/archived',
      icon: ArchiveIcon,
      title: t('sideBar.archived'),
    },
  ];

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
            >
              <Avatar
                component={RouterLink}
                src={avatarUrl}
                sx={{
                  cursor: 'pointer',
                  width: 64,
                  height: 64
                }}
                to="/app/account"
              >
                {getInitials(userData?.name)}
              </Avatar>
              <Typography
                color="textPrimary"
                variant="h5"
                style={{ marginTop: 10 }}
              >
                {userData?.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {userData?.role}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <List>
                {items.map((item) => (
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
                ))}
              </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
            >
              <Avatar
                component={RouterLink}
                src={avatarUrl}
                sx={{
                  cursor: 'pointer',
                  width: 64,
                  height: 64
                }}
                to="/app/account"
              >
                {getInitials(userData?.name)}
              </Avatar>
              <Typography
                color="textPrimary"
                variant="h5"
                style={{ marginTop: 10 }}
              >
                {userData?.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {userData?.role}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <List>
                {items.map((item) => (
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
                ))}
              </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Select
              sx={{
                alignSelf: 'flex-end',
                alignContent: 'center',
                width: '90%',
                margin: '5%'
              }}
              value={language}
              onChange={(event) => changeLanguage(event.target.value)}
            >
              <MenuItem value="nl">Nederlands</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
