import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProjectList from 'src/pages/ProjectList';
import ArchivedList from 'src/pages/ArchiveList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import Project from './pages/Project';
import OperatorList from './pages/OperatorList';
import Dashboard from './pages/Dashboard';
import OperativeHours from './pages/OperativeHours';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'operatives', element: <OperatorList /> },
      { path: 'projects', element: <ProjectList /> },
      { path: 'archived', element: <ArchivedList /> },
      { path: 'project/:id', element: <Project /> },
      { path: 'operative/:id', element: <OperativeHours /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to={sessionStorage.getItem('user') ? '/app/dashboard' : '/login'} /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
