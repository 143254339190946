import { Helmet } from 'react-helmet';
import React from 'react';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import CustomerListResults from 'src/components/customer/CustomerListResults';
import auth from '../firebase';

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { customers: [], currentUser: {} };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
        this.getCurrentUser();
      }
    });
  }

  getCurrentUser() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const currentUser = userSnap.data();
        this.setState({ currentUser });
      });
  }

  getUserData() {
    const promises = [];
    promises.push(this.getUsers());
    const usersList = auth.app.functions('europe-west1').httpsCallable('getuserlist');
    promises.push(usersList());
    Promise.all(promises).then(([, authData]) => {
      setTimeout(() => {
        const { customers } = this.state;
        const currentUserData = JSON.parse(JSON.stringify(customers));
        const authPersons = authData.data;
        currentUserData.forEach((person, index) => {
          const userEntry = authPersons.find((p) => p.uid === person.id);
          if (userEntry) {
            currentUserData[index] = { ...person, ...userEntry };
          }
        });
        this.setState({ customers: currentUserData });
      }, 100);
    }).catch(() => null);
  }

  setCustomers(customers) {
    this.setState({
      customers
    });
  }

  getUsers() {
    return auth.app.firestore()
      .collection('users')
      .get()
      .then((snap) => {
        const users = [];
        const promises = [];
        snap.docs.forEach((doc) => {
          if (doc.data().avatar) {
            promises.push(auth.app.storage().ref(doc.data().avatar).getDownloadURL().then((url) => {
              users.push({ ...doc.data(), ...{ id: doc.id, avatarUrl: url } });
            })
              .catch(() => users.push({ ...doc.data(), ...{ id: doc.id } })));
          } else {
            users.push({ ...doc.data(), ...{ id: doc.id } });
          }
        });
        return Promise.all(promises).then(() => {
          this.setState({ customers: users });
        });
      });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const { customers, currentUser } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {t('common.users')}
            {' '}
            | Elan daily reporting
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <CustomerListResults refresh={() => this.getUserData()} handler={(prop) => this.setCustomers(prop)} customers={customers} currentUser={currentUser} />
        </Box>
      </>
    );
  }
}

export default withTranslation()(CustomerList);
