import logo from '../assets/elan_transparent.png';

const Logo = (props) => (
  <img
    alt="Logo"
    style={{ paddingTop: 5 }}
    height="59"
    src={logo}
    {...props}
  />
);

export default Logo;
