import { useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut, Chart } from 'react-chartjs-2';
import moment from 'moment';
import { green, red } from '@material-ui/core/colors';
import { Zap as EnableIcon } from 'react-feather';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  Container,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  Grid,
  IconButton,
  TableSortLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import getInitials from 'src/utils/getInitials';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import auth from '../../firebase';

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw() {
    // eslint-disable-next-line prefer-rest-params
    originalDoughnutDraw.apply(this, arguments);

    const { chart } = this.chart;
    const { ctx } = chart;
    const { width } = chart;
    const { height } = chart;

    const fontSize = (height / 114).toFixed(2);
    ctx.font = `${fontSize}em Verdana`;
    ctx.textBaseline = 'middle';

    const { text } = chart.config.data;
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
  }
});

const ProjectListResults = ({
  projects, userData, archived, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [code, setCode] = useState('');
  const [customer, setCustomer] = useState('');
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileDialog, setFileDialog] = useState(false);
  const [fileInput, setFileInput] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [uploaded, setUploaded] = useState('');
  const sorts = [{
    id: 'name',
    name: 'jobsAndProjects.projectName',
  }, {
    id: 'createdAt',
    name: 'jobsAndProjects.created',
  }];

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = projects.map((c) => c.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const search = (event) => {
    const txt = event.target.value.toLowerCase();
    setSearchInput(txt);
    if (txt && txt.length > 0) {
      setFilteredCustomers(projects.filter((c) => c.name.toLowerCase().includes(txt) || c.id.toLowerCase().includes(txt)));
    } else {
      setFilteredCustomers([]);
    }
  };

  function compare(a, b, type, ord) {
    const str = (typeof a[type] === 'string' || a[type] instanceof String);
    const aVar = str ? a[type].toLowerCase() : a[type];
    const bVar = str ? b[type].toLowerCase() : b[type];
    if (aVar < bVar) {
      return ord ? -1 : 1;
    }
    if (aVar > bVar) {
      return ord ? 1 : -1;
    }
    return 0;
  }

  function changeSort(type) {
    if (type) {
      if (orderBy === type) {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(type);
        const newProjects = projects;
        newProjects.sort((a, b) => compare(a, b, type, newOrder === 'asc'));
        setFilteredCustomers(newProjects);
      } else {
        setOrderBy(type);
        const newProjects = projects;
        newProjects.sort((a, b) => compare(a, b, type, order === 'asc'));
        setFilteredCustomers(newProjects);
      }
    }
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  /*
  CHANGE TO DELETE PROJECTS
  --> DELETE JOBS & DRAWINGS (UNLESS SAVED AS FAVORITE)
  --> DELETE PROJECT FROM USER PROJECTS
  --> DELETE PROJECT FROM COMPANY PROJECTS
  --> DELETE PROJECT ITSELF
  */
  function deleteUsers() {
    setLoading(true);
    const promises = [];
    Promise.all(promises).finally(() => {
      selectedCustomerIds.forEach((uid) => {
        auth.app.firestore().collection('projects').doc(uid).delete();
      });
      rest.handler(projects.filter((c) => !selectedCustomerIds.includes(c.id)));
      setLoading(false);
      setSelectedCustomerIds([]);
    });
  }

  function loadNewProjectDetails() {
    setLoading(false);
    setModalOpened(true);
  }

  async function addProject() {
    setLoading(true);
    setModalOpened(false);
    return auth.app.firestore().collection('projects').doc(code).set({
      address,
      createdAt: new Date(),
      reportdates: [],
      emails: [],
      missingReportEmails: [],
      country,
      name,
      picture,
      description: description,
      disabled: false,
    })
      .then(async (set) => {
        const projectWeatherCall = auth.app.functions('europe-west1').httpsCallable('refreshProjectWeather');
        await projectWeatherCall();
        setLoading(false);
        rest.refresh();
        return set;
      });
  }

  function disableUsers() {
    const disabled = !archived;
    setLoading(true);
    const promises = [];
    selectedCustomerIds.forEach((uid) => {
      promises.push(auth.app.firestore().collection('projects').doc(uid).update({ disabled }));
    });
    Promise.all(promises).then(() => {
      rest.handler(projects.filter((c) => !selectedCustomerIds.includes(c.id)));
      setLoading(false);
      setSelectedCustomerIds([]);
    });
  }

  function uploadLink(fileInputEvent) {
    const storageRef = auth.app.storage().ref();

    if (fileInputEvent.length !== 0) {
      // Loops through all the selected files
      // create a storage reference
      const storage = storageRef.child(`projectimages/${fileInputEvent[0].name}`);

      // upload file
      const upload = storage.put(fileInputEvent[0]);

      // update progress bar
      upload.on(
        'state_changed',
        (snapshot) => {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },

        () => {
          alert('error uploading file');
        },

        () => {}
      );
      upload.then(() => {
        setImageUrl(null);
        setFileDialog(false);
        setUploaded(fileInputEvent[0].name);
        auth.app.storage().ref(`projectimages/${fileInputEvent[0].name}`).getDownloadURL().then((url) => setImageUrl(url));
        setPicture(`projectimages/${fileInputEvent[0].name}`);
        const event = document.createEvent('Event');
        event.initEvent('user-data-loaded', true, true);
        window.dispatchEvent(event);
      });
    } else {
      alert('No file chosen');
    }
  }

  return (
    <Container maxWidth={false}>
      <Box>
        <Box>
          <Card>
            <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{ maxWidth: 500, width: '60%' }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={search}
                  placeholder={t('jobsAndProjects.search')}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {selectedCustomerIds && selectedCustomerIds.length > 0
                  ? (
                    <>
                      <Button
                        style={{marginLeft: 12}}
                        color="error"
                        variant="contained"
                        onClick={() => deleteUsers()}
                      >
                        <div style={{paddingRight: 8, paddingLeft: 8}}>{t('jobsAndProjects.delete')}</div>
                      </Button>
                      <Button
                        style={{ marginLeft: 12, backgroundColor: 'grey' }}
                        variant="contained"
                        onClick={() => disableUsers()}
                      >
                        <div style={{paddingRight: 8, paddingLeft: 8}}>{t(!archived ? 'jobsAndProjects.disable' : 'jobsAndProjects.enable')}</div>
                      </Button>
                    </>
                  ) : !archived ? (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginLeft: 12 }}
                      onClick={() => loadNewProjectDetails()}
                    >
                      <div style={{paddingRight: 8, paddingLeft: 8}}>{t('jobsAndProjects.add')}</div>
                    </Button>
                  ) : null}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Card>
          <PerfectScrollbar>
            <Box sx={{ minWidth: isMobile ? 200 : 500 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.length === projects.length}
                        color="primary"
                        indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < projects.length
                    }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => changeSort('name')}
                      >
                        Project Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Project Id
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <TableSortLabel
                      active={orderBy === 'createdAt'}
                        direction={orderBy === 'createdAt' ? order : 'asc'}
                        onClick={() => changeSort('createdAt')}
                      >
                        Created
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ opacity: loading ? 0.3 : 1 }}>
                  {(searchInput.length > 0 ? filteredCustomers : projects).slice(0 + page * limit, limit * (page + 1)).map((project) => (
                    <TableRow
                      sx={{ cursor: project.disabled ? 'auto' : 'pointer', backgroundColor: project.disabled ? 'rgba(245,245,245 , 0.5 )' : null }}
                      hover
                      key={project.id}
                      selected={selectedCustomerIds.indexOf(project.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selectedCustomerIds.indexOf(project.id) !== -1}
                          onChange={(event) => handleSelectOne(event, project.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            maxWidth: isMobile ? 40 : '50%',
                            opacity: customer.disabled ? 0.5 : 1
                          }}
                        >
                          {!isMobile && (
                          <Avatar
                            src={null}
                            sx={{ mr: 2 }}
                          >
                            {getInitials(project.name)}
                          </Avatar>
                          )}
                          <Typography
                            color="textPrimary"
                            variant={isMobile ? 'body2' : 'body1'}
                          >
                            {project.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ maxWidth: isMobile ? 40 : '30%', opacity: customer.disabled ? 0.5 : 1 }}>
                        {project.id}
                      </TableCell>
                      <TableCell sx={{ maxWidth: isMobile ? 40 : '20%', textAlign: 'right', opacity: customer.disabled ? 0.5 : 1 }}>
                        {moment(project.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={projects.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[6, 12, 18]}
          />
        </Card>
      </Box>
      <Dialog
        onBackdropClick={() => setModalOpened(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 3,
          minWidth: '100vw',
          justifyContent: 'center',
        }}
        open={modalOpened}
      >
        <Grid
          container
          width={isMobile ? '90vw' : null}
          spacing={0}
          sx={{ backgroundColor: 'white'}}
        >
          <form
            noValidate
          >
            <Card>
              <CardHeader
                subheader={t('jobsAndProjects.projectDetails')}
                title="Project"
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.projectName')}
                      name="projectname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.projectDescription')}
                      name="projectdescripton"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.address')}
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.country')}
                      name="country"
                      value={country}
                      required
                      variant="outlined"
                      onChange={(e) => setCountry(e.target.value)}
                      select
                    >
                      <MenuItem value={'Belgium'}>Belgium</MenuItem>
                      <MenuItem value={'Netherlands'}>Netherlands</MenuItem>
                      <MenuItem value={'Germany'}>Germany</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.projectCode')}
                      name="projectcode"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      required
                      helperText={t('jobsAndProjects.codeExample')}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <div style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
                      <Button
                        variant="contained"
                        component="label"
                      >
                        Select File
                        <input
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          onChange={(event) => {
                            setFileInput(event.target.files);
                            setTimeout(uploadLink(event.target.files), 200);
                          }}
                          hidden
                        />
                      </Button>
                      <span style={{ wordWrap: 'break-word', marginLeft: 6 }}>{fileInput && fileInput.length > 0 ? fileInput[0].name : 'No file selected'}</span>
                    </div>
                  </Grid>
                  {imageUrl?.length > 0 && (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <img
                      src={imageUrl}
                      srcSet={imageUrl}
                      alt={`image-project`}
                      height={164}
                      loading="lazy"
                    />
                  </Grid>)}
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={{ backgroundColor: 'lightgrey', color: 'black' }}
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  onClick={() => setModalOpened(false)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => addProject()}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Card>
          </form>
        </Grid>
      </Dialog>
    </Container>
  );
};

ProjectListResults.propTypes = {
  projects: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  archived: PropTypes.bool.isRequired,
};

export default ProjectListResults;
