import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  Breadcrumbs,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestUsers from 'src/components/dashboard/LatestUsers';
import auth from '../firebase';
import firebase from 'firebase';
import WorkingHours from 'src/components/operative/WorkingHours';
import TotalNumber from 'src/components/operative/TotalJobs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { indigo } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import {
  Tool as ToolIcon,
  Briefcase as BriefCaseIcon,
} from 'react-feather';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

class OperativeHours extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    oneWeekAgo.setHours(0, 0, 0, 0);

    this.state = {
      operativeId: '',
      activeProjects: [],
      reports: [],
      beginDate: oneWeekAgo,
      endDate: new Date(),
      totalTravel: 0,
      totalWork: 0,
    };
  }

  componentDidMount() {
    const splitted = window.location.pathname.split('operative/');
    this.setState({ operativeId: decodeURI(splitted[1]), screenHeight: window.innerHeight });
    this.getUserReportInfo(decodeURI(splitted[1]));
  }

  chunkArray(arr, chunkSize) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }

  async getUserReportInfo(operative) {
    const {beginDate, endDate} = this.state;
    await auth.app.firestore().collection('operatives')
      .doc(operative.trim())
      .get()
      .then(async (response) => {
        console.log(response);
        let data;
        if (!response.data()) {
          console.log(response.data());
          const res = await auth.app.firestore().collection('operatives')
          .where('id', '==', operative)
          .get();

          data = res?.docs[0]?.data();
        } else {
          data = response.data();
        }
        const activeProjects = data?.activeProjects;
        if (activeProjects?.length) {
          console.log(activeProjects);
          this.setState({ activeProjects });
          console.log(activeProjects);
          const allReports = activeProjects.map((ap) => ap.reports).flat();
          const reportChunks = this.chunkArray(allReports, 10);

          const promises = reportChunks.map(chunk => {
            return auth.app.firestore().collection('reports')
              .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
              .where('signed', '==', true)
              .get();
          });

          return Promise.all(promises)
            .then(results => {
              console.log(results);
              const reports = [];
              results.forEach(result => {
                result.forEach(doc => {
                  if (doc.data().date.toDate() >= beginDate && doc.data().date.toDate() <= endDate) {
                    reports.push({ ...doc.data(), ...{ id: doc.id } });
                  }
                });
              });
              // Process mergedResults here
              const { activeProjects } = this.state;
  
              let totalTravel = 0;
              let totalWork = 0;
  
              activeProjects.forEach((project, index) => {
                const filteredReports = reports.filter((r) => r.project === project.project) || [];
                activeProjects[index].reportData = filteredReports;
                const {travelHours, workingHours} = filteredReports.reduce((previous, current) => {
                  const userTimes = current.userTimes?.find((u) => u.user === operative);
                  if (userTimes?.travelHours) {
                    previous.travelHours += userTimes.travelHours;
                  }
    
                  if (userTimes?.workHours) {
                    previous.workingHours += userTimes.workHours;
                  }
    
                  return previous
                }, {
                  travelHours: 0,
                  workingHours: 0,
                });
                activeProjects[index].workingHours = workingHours;
                activeProjects[index].travelHours = travelHours;
                totalTravel += travelHours;
                totalWork += workingHours;
              });
    
              this.setState({reports, activeProjects: activeProjects.filter((p) => p.workingHours + p.travelHours > 0) || [], totalTravel, totalWork})
            })
            .catch(error => {
              console.error("Error fetching reports:", error);
            });
        }
      });
  }

  changedDate(event, param) {
    const newDate = event.toDate();
    if (param === 'beginDate') {
      newDate.setHours(0, 0, 0, 0);
    } else {
      newDate.setHours(23, 59, 59, 999);
    }
    this.setState({ [param]: newDate });

    setTimeout(() => this.getUserReportInfo(this.state.operativeId), 100);
  }


  render() {
    const {
      activeProjects, reports, beginDate, endDate, totalTravel, totalWork, operativeId
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Operative Hours | Elan Daily Reporting
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginLeft: 3, marginBottom: 2 }}>
            <Link underline="hover" color="inherit" href="/app/operatives">
              Operatives
            </Link>
            <Typography color="text.primary">{operativeId}</Typography>
          </Breadcrumbs>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                {<TotalNumber total={totalTravel} title={'Total Travel Hours'} icon={<BriefCaseIcon />} color={indigo[600]} />}
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                {<TotalNumber total={totalWork} title={'Total Working Hours'} icon={<ToolIcon />} />}
              </Grid>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={12}
              >
                  <Card
                      sx={{ height: '100%' }}
                  >
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                        sx={{ justifyContent: 'space-between' }}
                      >
                        <Grid item width='100%'>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Period
                          </Typography>
                          <div style={{width: '100%', flex: 1, flexDirection: 'column', justifyContent: 'space-between'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker value={dayjs(beginDate)} onChange={(event) => this.changedDate(event, 'beginDate')} format='DD/MM/YYYY' localeText={{ start: 'Check-in', end: 'Check-out' }} />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker value={dayjs(endDate)} onChange={(event) => this.changedDate(event, 'endDate')} format='DD/MM/YYYY' localeText={{ start: 'Check-in', end: 'Check-out' }}  sx={{marginLeft: 2}} />
                            </LocalizationProvider>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xl={6}
                xs={12}
              >
                {<WorkingHours activeProjects={activeProjects} />}
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xl={6}
                xs={12}
              >
                {<LatestOrders currentReports={reports} signed={true} noStatus={true} />}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default OperativeHours;
